/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Link, Button } from '@material-ui/core'
import { Link as RouterLink } from '@reach/router'
import clsx from 'clsx'
import DonateForm from '../components/DonateForm'
import MoreHelpSection from '../components/DonatePage/MoreHelpSection'
import SupporterLevels from '../components/DonatePage/SupporterLevels'
//Images
import heroImage from '../assets/img/make-therapy-affordable-source.jpg'
import donorImage from '../assets/img/dmitry-ratushny-xsGApcVbojU-unsplash.jpg'
import sponsorImage from '../assets/img/nghia-le-V3DokM1NQcs-unsplash.jpg'
import foundingSponsorImage from '../assets/img/Lit-Path-Founding-Sponsorship.jpg'
import fundraiserImage from '../assets/img/2023-Fundraiser.jpg'
import Headers from '../components/Headers'

const primaryColor = '#1B3666'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'white',
      marginTop: '64px',
      marginBottom: '64px',
      gap: '64px',
    },
  },
  cardContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '32px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    borderRadius: '100px',
    background: primaryColor,
    color: 'white',
    padding: '16px 32px',
    height: '59px',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '27px',
    boxShadow:
      '0px 0px 0px 0px #0000001A,0px 4px 8px 0px #0000001A,0px 14px 14px 0px #00000017,0px 32px 19px 0px #0000000D,0px 56px 23px 0px #00000003,0px 88px 25px 0px #00000000',
    '&:hover': {
      backgroundColor: '#132648',
    },
  },
  helpSection: {
    width: '100%',
    margin: 'none',
  },
  mobileSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    alignItems: 'center',
    padding: '0px 16px',
  },
  mobileImage: {
    width: '100%',
    height: '320px',
    maxWidth: '420px',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '6px',
    boxShadow:
      '0px 0px 0px 0px #0000001A,0px 4px 8px 0px #0000001A,0px 14px 14px 0px #00000017,0px 32px 19px 0px #0000000D,0px 56px 23px 0px #00000003,0px 88px 25px 0px #00000000',
  },
  heroTitle: {
    color: primaryColor,
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '42px',
    letterSpacing: '-0.015em',
  },
  sectionTitle: {
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '36px',
    color: primaryColor,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    color: 'black',
    textAlign: 'center',
    letterSpacing: '-0.013em',
  },
  sectionContent: {
    fontFamily: 'Inter',
    color: '#000000',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '150%',
    letterSpacing: '0.005em',
  },
  marketingImage: {
    width: '100%',
    maxWidth: '420px',
    height: 'auto',
    objectFit: 'contain',
    boxShadow:
      '0px 0px 0px 0px #0000001A, 0px 2.9271488189697266px 7.317872047424316px 0px #0000001A, 0px 13.17216968536377px 13.17216968536377px 0px #00000017, 0px 30.735063552856445px 19.02646827697754px 0px #0000000D, 0px 55.61582946777344px 21.953617095947266px 0px #00000003, 0px 86.35089111328125px 23.417190551757812px 0px #00000000',
  },
}))

const marketingImages = [
  { image: foundingSponsorImage, alt: 'Litpath sponsorship campagin card' },
  { image: fundraiserImage, alt: 'Litpath fundraiser campaign card' },
]

export default function DonateMobile() {
  const classes = useStyles()
  //scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Headers
        title="Donate To Support Addiction Recovery!"
        type="website"
        description="Donate to support addiction recovery therapy today! Support those struggling with sex addiction, alcohol addiction, technology addiction, gambling addiction, pornography addiction, work addiction, shopping addiction, food disorders, and other addictions."
      />
      <div className={classes.mainContainer}>
        <div className={classes.mobileSection}>
          <Typography component="h1" className={classes.heroTitle}>
            Be the Beacon of Hope for Recovery
          </Typography>
          <img
            align="center"
            className={classes.mobileImage}
            alt="a glass cup with coins and a plant growing out of it"
            src={heroImage}
          />
          <div className={classes.columnContainer}>
            <Typography component="h3" className={classes.subtitle}>
              Your Support Creates Life-Changing Opportunities.
            </Typography>
            <Typography className={classes.sectionContent} style={{ fontSize: '18px' }} component="p">
              At Lit Path, we are on a mission to light the path to recovery for individuals struggling with compulsive
              behavior(s). By supporting us, you can make a profound difference in the lives of those seeking healing
              and transformation. Together, we can empower individuals to overcome compulsive behavior(s), find hope,
              and rebuild their lives. Join us in being the beacon of hope for those in need.
            </Typography>
            <div className={classes.buttonContainer}>
              <Link component={RouterLink} to="/donate-form">
                <Button className={classes.button}>Donate Today</Button>
              </Link>
            </div>
          </div>
        </div>

        <div className={classes.mobileSection}>
          <img
            align="center"
            className={classes.mobileImage}
            src={donorImage}
            alt="a close up of a child's face looking through a small hole with light shining through"
          />
          <Typography className={classes.sectionTitle} component="h2">
            Make a personal impact
          </Typography>
          <div className={classes.cardContainer}>
            <div className={classes.columnContainer}>
              <Typography component="h5" className={classes.subtitle} style={{ fontWeight: 'bold' }}>
                Every Donation Counts
              </Typography>
              <Typography className={classes.sectionContent}>
                Your individual donation can have a direct and tangible impact on someone's recovery journey. By
                contributing to Lit Path, you are helping us provide partial scholarships for psychotherapy, ensuring
                that individuals receive the support they need to heal and thrive. <br /> <br />
                No amount is too small, and every dollar brings us closer to our goal of making recovery therapy
                accessible for all. Your generosity not only transforms lives but also fosters a stronger, more
                compassionate community. Make a personal impact today by donating to Lit Path and being a catalyst for
                change.
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.mobileSection}>
          <img
            align="center"
            className={classes.mobileImage}
            src={sponsorImage}
            alt="a person standing on a mountain top with their thumbs up seeing the sun shining through the clouds"
          />
          <Typography className={classes.sectionTitle} component="h2">
            Inspire transformation through sponsorships
          </Typography>
          <div className={classes.cardContainer}>
            <div className={classes.columnContainer}>
              <Typography component="h5" className={classes.subtitle} style={{ fontWeight: 'bold' }}>
                Ignite Change on a Larger Scale
              </Typography>
              <Typography component="body1" className={classes.sectionContent}>
                Sponsorships play a vital role in fueling the transformational work we do at Lit Path. By becoming a
                sponsor, you have the power to ignite change on a larger scale and support multiple individuals on their
                recovery journeys. <br /> <br />
                Through sponsorships, we can expand our scholarship program, reach more therapists, and extend our
                impact to those who need it the most.
              </Typography>
              <div className={classes.columnContainer} style={{ gap: '32px', width: '100%' }}>
                <Typography
                  component="h5"
                  className={clsx(classes.cardHeader, classes.subtitle)}
                  style={{ fontWeight: 'bold' }}
                >
                  Supporter Levels:
                </Typography>
                <SupporterLevels />

                <Typography className={classes.sectionContent}>
                  🌟: Permanently listed as a “Founding Sponsor” on website <br />
                  ★: Listed under “Our Supporters” on our website
                </Typography>
              </div>
              <div className={classes.buttonContainer}>
                <Link component={RouterLink} to="/contact">
                  <Button className={classes.button}>Contact Us</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.mobileSection}>
          <div className={classes.columnContainer}>
            {marketingImages.map((image, imgIndex) => (
              <img key={imgIndex} src={image.image} alt={image.alt} className={classes.marketingImage} />
            ))}
          </div>
        </div>

        <div className={classes.helpSection} style={{ width: '100%' }}>
          <MoreHelpSection />
        </div>
      </div>
    </>
  )
}
