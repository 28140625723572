import React, { useEffect, useState } from 'react'
import TopNavBar from '../components/TopNavBar'
import Footer from '../components/Footer'
import { Grid, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import landingImage from '../assets/img/adam-birkett-336287-unsplash.jpg'
import Resource from '../components/More Resources/Resource'
import OrganizationSlider from '../components/More Resources/OrganizationSlider'
import CrisisModal from '../components/More Resources/CrisisModal'
import img1 from '../assets/img/tunnel.jpg'
import img2 from '../assets/img/family-services.jpeg'
import img3 from '../assets/img/hill.jpg'
import img4 from '../assets/img/sunrise.jpg'
import img5 from '../assets/img/sign.jpg'
import ComingSoon from '../components/More Resources/ComingSoon'
import Headers from '../components/Headers'

const useStyles = makeStyles((theme) => ({
  hero: {
    display: 'flex',
    width: '100%',
    padding: '80px 200px',
    paddingTop: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFF',
    [theme.breakpoints.down('sm')]: {
      padding: '32px 16px',
      paddingTop: '48px',
      flexDirection: 'column',
      gap: '24px',
    },
  },
  heroContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '48px',
    flexShrink: '0',
    [theme.breakpoints.down('sm')]: {
      gap: '16px',
    },
  },
  heroTitle: {
    display: 'flex',
    flexDirection: 'column',
    color: '#1B3666',
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
      fontWeight: '600',
    },
  },
  heroDescription: {
    color: '#000',
    fontSize: '18px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: '400',
    lineHeight: '150%',
    letterSpacing: '0.2px',
  },
  heroImg: {
    width: '454px',
    height: '454px',
    borderRadius: '6px',
    objectFit: 'cover',
    boxShadow:
      '0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 4px 8px 0px rgba(0, 0, 0, 0.10), 0px 14px 14px 0px rgba(0, 0, 0, 0.09), 0px 32px 19px 0px rgba(0, 0, 0, 0.05), 0px 56px 23px 0px rgba(0, 0, 0, 0.01), 0px 88px 25px 0px rgba(0, 0, 0, 0.00)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '450px',
      height: '285px',
      boxShadow: 'none',
    },
  },
  btnCrisis: {
    background: '#1B3666',
    borderRadius: '100px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    border: 'none',
    color: '#FFF',
    fontSize: '20px',
    fontWeight: 600,
    padding: '16px 32px',
    cursor: 'pointer',
    fontFamily: 'Inter',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#132648',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      padding: '10px 20px',
    },
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '40px',
    padding: '80px',
    background: '#FFF',
    [theme.breakpoints.down('sm')]: {
      padding: '32px 16px',
      gap: '16px',
    },
  },
  lowercaseTitle: {
    color: '#1B3666',
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '130%',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
  },
  descriptionText: {
    color: '#000',
    fontSize: '18px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: '0.18px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      textAlign: 'left',
    },
  },
  titleContainer: {
    display: 'flex',
    width: '70%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '32px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  resourcesCategoriesContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    padding: '40px',
    gap: '25px',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
    },
  },
  contactButton: {
    display: 'flex',
    height: '50px',
    padding: '10px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    background: '#1B3666',
    fontSize: '18px',
    color: '#FFF',
    fontFamily: 'Inter',
    fontWeight: 700,
    textTransform: 'capitalize',
    border: 'none',
    cursor: 'pointer',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      backgroundColor: '#132648',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
    },
  },
}))

const resourcesCards = [
  {
    title: 'Drugs and Alcohol',
    image: img1,
    items: [
      { name: 'Alcoholics Anonymous', url: 'https://www.aa.org/' },
      { name: 'Heroin Anonymous', url: ' https://heroinanonymous.org/' },
      { name: 'Marijuana Anonymous', url: 'http://www.marijuana-anonymous.org/' },
      { name: 'Narcotics Anonymous', url: 'http://www.na.org/' },
      { name: 'Nicotine Anonymous', url: 'http://www.nicotine-anonymous.org/' },
      { name: 'Chemically Dependent Anonymous', url: 'http://www.cdaweb.org/' },
      { name: 'Cocaine Anonymous', url: 'http://www.ca.org/' },
      { name: 'Crystal Meth Anonymous', url: 'http://www.crystalmeth.org/' },
      { name: 'Pills Anonymous', url: 'https://www.pillsanonymous.org/' },
      { name: 'Prescription Anonymous', url: 'https://www.prescriptionsanonymous.com/' },
    ],
  },
  {
    title: 'Family Support',
    image: img2,
    items: [
      { name: 'Al-Anon/Alateen', url: 'http://www.al-anon.alateen.org/' },
      { name: 'Adult Children of Alcoholics', url: 'http://www.adultchildren.org/' },
      { name: 'Co-Anon/ Cocaine Anonymous', url: 'http://www.co-anon.org/' },
      { name: 'Co-Dependents Anonymous', url: 'http://www.codependents.org/' },
      { name: 'Nar-Anon', url: 'http://nar-anon.org/' },
      { name: 'Families Anonymous', url: 'http://www.familiesanonymous.org/' },
      { name: 'Parents Anonymous', url: 'http://www.parentsanonymous.org/' },
      { name: 'Survivors of Incest Anonymous', url: 'http://www.siawso.org/' },
    ],
  },
  {
    title: 'Food Challenges',
    image: img3,
    items: [
      { name: 'Anorexics and Bulimics Anonymous', url: 'http://aba12steps.org/' },
      { name: 'Compulsive Eaters Anonymous', url: 'http://www.ceahow.org/' },
      { name: 'Eating Disorders Anonymous', url: 'http://www.eatingdisordersanonymous.org/' },
      { name: 'Food Addicts Anonymous', url: 'http://foodaddictsanonymous.org/' },
      { name: 'Food Addicts in Recovery Anonymous', url: 'http://www.foodaddicts.org/' },
      { name: 'Overeaters Anonymous', url: 'http://www.oa.org/' },
    ],
  },
  {
    title: 'Sex and Relationships',
    image: img4,
    items: [
      { name: 'Love Addicts Anonymous', url: 'http://www.loveaddicts.org/' },
      { name: 'Recovering Couples Anonymous', url: 'http://www.recovering-couples.org/' },
      { name: 'Sexaholics Anonymous', url: 'http://www.sa.org' },
      { name: 'Sex Addicts Anonymous', url: 'http://www.sexaa.org/' },
      { name: 'Sex and Love Addicts Anonymous', url: 'http://www.slaafws.org/' },
      { name: 'Sexual Compulsive Anonymous', url: 'http://www.sca-recovery.org/' },
      { name: 'Sexual Recovery Anonymous', url: 'http://sexualrecovery.org/' },
    ],
  },
  {
    title: 'Other',
    image: img5,
    items: [
      { name: 'Bettors Anonymous', url: 'http://www.bettorsanonymous.org/' },
      { name: 'Clutterers Anonymous', url: 'http://www.clutterersanonymous.net/' },
      { name: 'Debtors Anonymous', url: 'http://debtorsanonymous.org/' },
      { name: 'Dual Recovery Anonymous', url: 'http://www.draonline.org/' },
      { name: 'Emotions Anonymous', url: 'https://emotionsanonymous.org/' },
      { name: 'Gamblers Anonymous', url: 'http://www.gamblersanonymous.org/' },
      {
        name: 'Kleptomaniacs and Shoplifters Anonymous',
        url: ' https://theshulmancenter.com/casa-support-groups.html',
      },
      { name: 'Recoveries Anonymous', url: 'http://www.r-a.org/' },
      { name: 'Self Injury Anonymous', url: 'http://www.selfmutilatorsanonymous.org/' },
      { name: 'Spenders Anonymous', url: 'http://www.spenders.org/' },
      { name: 'Trauma Anonymous', url: 'http://traumaanonymous.com/' },
      { name: 'Workaholics Anonymous', url: 'http://www.workaholics-anonymous.org/' },
    ],
  },
]

const MoreResources = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Headers
        title="Find More Resources"
        type="website"
        description="Connect to over 40 fellowship programs, also known as 12-step programs, across the US. Find an intensive program or inpatient program. Lit Path has provided a listing of programs they believe best support the recovery journey."
      />
      <TopNavBar />
      <div className={classes.hero}>
        <Grid container spacing={4} style={{ maxWidth: '1200px' }}>
          <Grid item xs={12} md={6} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            <div className={classes.heroContainer}>
              <Typography component="h1" className={classes.heroTitle} align="left">
                More Resources
              </Typography>
              {isMobile && (
                <img
                  align="center"
                  className={classes.heroImg}
                  src={landingImage}
                  alt="a person looking at a landscape"
                />
              )}
              <Typography className={classes.heroDescription} align="left" component="p">
                We understand that the journey to recovery from compulsive behavior(s) requires a comprehensive and
                multi-faceted approach. In addition to providing partial scholarships for psychotherapy, we believe in
                the power of community support and access to additional resources. This page serves as a gateway to
                valuable resources that can further aid individuals in their recovery journey.
              </Typography>
              {!isMobile && (
                <button className={classes.btnCrisis} onClick={handleClickOpen}>
                  Experiencing a Crisis?
                </button>
              )}
            </div>
          </Grid>
          {!isMobile && (
            <Grid align="center" item xs={12} md={6}>
              <img
                align="center"
                className={classes.heroImg}
                src={landingImage}
                alt="a person looking at a landscape"
              />
            </Grid>
          )}
        </Grid>
        {isMobile && (
          <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
            <button className={classes.btnCrisis} onClick={handleClickOpen}>
              Experiencing a Crisis?
            </button>
          </div>
        )}
      </div>
      <CrisisModal open={open} handleClose={handleClose} />
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography className={classes.lowercaseTitle} component="h2">
            Fellowship Programs
          </Typography>
          <Typography className={classes.descriptionText} component="p">
            Here, you will find links to various 12-step programs, also known as fellowship groups, which offer a
            supportive network of individualas who have experienced similar challenges and are working towards healing
            and growth. These programs provide a space for sharing experience, seeking guidance, and fostering
            connections with others on the path to recovery.
          </Typography>
        </div>
        <div className={classes.resourcesCategoriesContainer}>
          {resourcesCards.map((category) => (
            <Resource title={category.title} links={category.items} image={category.image} />
          ))}
        </div>
      </div>
      <div id="intensive" className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography className={classes.lowercaseTitle} component="h2">
            Intensive Programs
          </Typography>
          <Typography className={classes.descriptionText} component="p">
            An intensive program offers a concentrated approach to compulsive behavior(s) recovery. It involves
            immersive therapy sessions, individual and group counseling, and skill-building activities over a shorter
            duration. Intensive programs are designed to provide focused support and education to individuals seeking to
            address their compulsive behavior(s) and develop coping strategies.
          </Typography>
        </div>
        <OrganizationSlider />
      </div>
      <div id="inpatient" className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography className={classes.lowercaseTitle} component="h2">
            Inpatient Programs
          </Typography>
          <Typography className={classes.descriptionText} component="p">
            An inpatient program provides a comprehensive and immersive treatment experience. It involves residing at a
            treatment facility for an extended period, typically several months, to receive intensive therapeutic
            interventions, counseling, and support. Inpatient programs offer a structured environment where individuals
            can focus solely on their recovery, receiving 24/7 care, medical assistance, and therapeutic activities to
            address the underlying causes of compulsive behavior(s) and facilitate lasting change.
          </Typography>
        </div>
        <ComingSoon />
        <Typography className={classes.descriptionText}>Please check back soon for inpatient programs</Typography>
      </div>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography className={classes.lowercaseTitle} component="h2">
            Any Questions?
          </Typography>
          <a href="/contact" style={{ textDecoration: 'none' }}>
            <button className={classes.contactButton}>Contact Us</button>
          </a>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default MoreResources
