import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import iitaplogo from '../../assets/img/IITAP-LOGO.png'
import dfgLogo from '../../assets/img/develop_for_good_logo.png'
import basLogo from '../../assets/img/bas_logo.png'
import crysalisLogo from '../../assets/img/crysalis_home_logo.jpg'
import brLogo from '../../assets/img/BRLogo.png'
import baiLogo from '../../assets/img/BAILogo.png'
import samsonLogo from '../../assets/img/samsonLogo.png'
import HorizontalSlider from './HorizontalSlider'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginTop: 32,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 80,
    },
  },
  orgImage: {
    width: '200px',
    height: '100px',
    objectFit: 'scale-down',
    [theme.breakpoints.down('sm')]: {
      width: '150px',
      height: '75px',
    },
  },
}))

const organizationImages = [
  {
    name: 'Chrysalis Home, a safe place for transformation',
    img: crysalisLogo,
    link: 'https://www.chrysalishome.org/',
  },
  {
    name: 'BAS Technologies, Discover Your Opportunities. Data Solutions | Application Development | Automation',
    img: basLogo,
    link: 'https://www.bas-technologies.com/',
  },
  {
    name: 'International Institute for Trauma and Addiction Professionals',
    img: iitaplogo,
    link: 'https://iitap.com/',
  },
  { name: 'Develop For Good', img: dfgLogo, link: 'https://www.developforgood.org/' },
  {
    name: 'Begin Again Institute',
    img: baiLogo,
    link: 'https://beginagaininstitute.com/',
  },
  {
    name: 'Boulder Recovery 14-Day Christian Intensive',
    img: brLogo,
    link: 'https://beginagaininstitute.com/programs/14-day-christian-intensive/',
  },
  {
    name: 'Samson Society',
    img: samsonLogo,
    link: 'https://samsonsociety.com/',
  },
]

const SupporterSlider = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <HorizontalSlider>
        {organizationImages.concat(organizationImages).map((org) => (
          <a href={org.link} target="_blank" key={org} rel="noreferrer">
            <img src={org.img} alt={`${org.name  } logo`} className={classes.orgImage} />
          </a>
        ))}
      </HorizontalSlider>
    </div>
  )
}

export default SupporterSlider
