import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink  } from '@reach/router'
import React from 'react'

export default function LinkButton({text, variant, bgColor, fontColor, route, className }) {
  const useStyles = makeStyles((theme) => ({
    button: {
      color: fontColor,
      backgroundColor: bgColor,
      textTransform: "none",
      "&:hover": {
        color: fontColor,
        backgroundColor: bgColor,
      },
      filter: "drop-shadow(0px 4px 4px rgba(0,0,0,0.25));",
      "& a": {
        flexGrow: 0,
      },
      "& *": {
        flexGrow: 0
      }
    },
    large: {
      borderRadius: "100px",
      padding: "20px 40px",
      fontWeight: "bold",
      fontSize: "20px"
    },
    medium: {
      borderRadius: "100px",
      padding: "12px 32px",
      fontWeight: "bold",
      fontSize: "18px"
    }
}))
  const classes = useStyles();
  
  return (
    <Button className={`${className} ${classes.button} ${classes[variant]}`} component={RouterLink} to={route}>
      {text}
    </Button>
  )
}
