import React, { useEffect, useState } from 'react'
import { navigate, Link as RouterLink } from '@reach/router'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Box, Button, Container, Grid, Typography, Link, useMediaQuery } from '@material-ui/core'
import TopNavBar from '../components/TopNavBar'
// import SearchBarTherapist from '../components/SearchBarTherapist'
// import bgImg from '../assets/img/bg-hands-op-150.png'
import bgImg from '../assets/img/lit-path-therapy-source.jpg'
import iitaplogo from '../assets/img/IITAP-LOGO.png'
import Footer from '../components/Footer'
import QuoteSection from '../components/HomePage/QuoteSection'
import GetMoreSupportSection from '../components/HomePage/GetMoreSupportSection'
import LinkButton from '../components/LinkButton'
import arrowButton from '../assets/img/arrow-right.png'
import whiteArrowButton from '../assets/img/white-arrow.svg'
import SupporterSlider from '../components/HomePage/SupporterSlider'

const primary = '#1B3666'
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  heroContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: 335,
    textAlign: 'left',
    padding: 16,
    paddingTop: 48,
    backgroundImage: `url(${bgImg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '80px',
      paddingRight: '80px',
    },
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 165px)',
      maxHeight: 840,
      alignItems: 'center',
      padding: 32,
      justifyContent: 'center',
    },
  },
  heroBox: {
    display: 'flex',
    width: '267px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      flexDirection: 'row',
      paddingLeft: '200px',
      paddingRight: '200px',
    },
  },
  fillerBox: {
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  donateButton: {
    marginTop: 25,
  },
  title: {
    fontSize: 32,
    marginBottom: 24,
    color: 'black',
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
      fontSize: 72,
    },
  },
  link: {
    width: '100%',
    height: '100%',
  },
  rowLinks: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  rowLink: {
    height: 80,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subtitle: {
    fontSize: 18,
    fontFamily: 'Inter',
    maxWidth: 240,
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
      maxWidth: '100%',
    },
  },
  ourSupporters: {
    display: 'flex',
    marginTop: 56,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '80px',
      paddingRight: '80px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 80,
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 200,
      paddingRight: 200,
    },
  },
  footer: {
    // padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: 'white',
    color: theme.palette.common.white,
  },
  supportericon: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    height: theme.spacing(12),
  },
  supporterTitle: {
    fontWeight: 700,
    fontSize: 28,
    marginBottom: 24,
    color: primary,
    [theme.breakpoints.up('sm')]: {
      fontSize: 48,
      marginBottom: 48,
    },
  },
  marginSection: {
    marginTop: 80,
  },
  arrowButton: {
    height: 20,
    paddingBottom: 3,
  },
}))

export default function MainLayout({ children }) {
  const classes = useStyles()
  const theme = useTheme()
  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  const greaterThanMid = useMediaQuery(theme.breakpoints.up('md'))
  // on clicking "Go", navigate to /find?q=value
  // const handleSearch = () => {
  //   navigate(`/find?q=${value}`)
  // }
  // const handleKeyPress = (event) => {
  //   if (event.key === 'Enter') {
  //     handleSearch()
  //   }
  // }

  return (
    <div className={classes.root}>
      {/* navbar */}
      <TopNavBar />
      {/* main content */}
      <Box className={classes.heroContent}>
        <Box className={classes.heroBox}>
          <Box sx={{ width: '100%' }}>
            <Typography variant="h1" component="h2" className={classes.title}>
              Find Hope
            </Typography>
            <Box sx={{ fontSize: 20 }}>
              <Typography component="h3" className={classes.subtitle}>
                We believe in lighting the path to recovery from compulsive behavior(s).
              </Typography>
            </Box>
            <LinkButton
              className={classes.donateButton}
              fontColor="black"
              bgColor="#FFDB15"
              route="/donate"
              text="Donate Today"
              variant={greaterThanMid ? 'large' : 'medium'}
            />
          </Box>
          <Box className={classes.fillerBox} />
        </Box>
      </Box>

      <Box className={classes.rowLinks}>
        <Box className={classes.rowLink} sx={{ bgcolor: primary, color: 'white' }}>
          <Link
            className={classes.link}
            component={RouterLink}
            to="/therapist-application"
            variant="body1"
            color="inherit"
            underline="none"
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 16,
                fontWeight: 700,
                fontSize: 18,
                height: '100%',
                [theme.breakpoints.up('md')]: { fontSize: 24 },
              }}
            >
              Become a Participating Therapist
              <img
                className={`${classes.arrowButton} ${classes.whiteArrow}`}
                src={whiteArrowButton}
                alt="white arrow"
              />
            </Box>
          </Link>
        </Box>
        <Box className={classes.rowLink} sx={{ bgcolor: '#D49F02' }}>
          <Link
            className={classes.link}
            component={RouterLink}
            to="/scholarships"
            variant="body1"
            color="inherit"
            underline="none"
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 16,
                fontWeight: 700,
                fontSize: 18,
                height: '100%',
                [theme.breakpoints.up('md')]: { fontSize: 24 },
              }}
            >
              Apply for a Scholarship
              <img className={classes.arrowButton} src={arrowButton} alt="black arrow" />
            </Box>
          </Link>
        </Box>
      </Box>

      {children}

      <Box className={classes.ourSupporters}>
        <Box sx={{ width: 'min(100%, 590px)', textAlign: 'center' }}>
          <Typography component="h2" className={classes.supporterTitle}>
            Our Supporters
          </Typography>
          <Typography style={{ fontSize: 18, fontFamily: 'Inter' }} component="p">
            Lit Path extends heartfelt gratitude to the generous contributions of these remarkable individuals and
            companies. Thank you for your unwavering dedication to our cause.
          </Typography>
        </Box>
        <SupporterSlider />
      </Box>

      <QuoteSection />
      <GetMoreSupportSection />

      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  )
}
